import PropTypes from "prop-types"
import React from "react"
import { Link } from 'gatsby';
import logo from '../../../images/logo.png';

const LayoutHeader = ({ siteTitle }) => {
  const [navdata] = React.useState([
    {
      id: 1,
      title: 'Home',
      url: '/',
      childs: []
    },
    {
      id: 2,
      title: 'About',
      url: '#',
      childs: [
        {
          id: 1,
          title: 'Company',
          url: '/about/company',
        },
        {
          id: 2,
          title: 'Case Studies',
          url: '/about/case-studies',
        },
        {
          id: 3,
          title: 'Partners',
          url: '/partners'
        },
        {
          id: 4,
          title: 'Careers',
          url: '/career'
        }
      ]
    },
    {
      id: 3,
      title: 'Services',
      url: '#',
      childs: [
        {
          id: 1,
          title: 'Salesforce Consulting',
          url: '/services/salesforce-consulting',
        },
        {
          id: 2,
          title: 'Marketing Automation',
          url: '/services/marketing-automation-services',
        },
        {
          id: 3,
          title: 'MuleSoft Consulting',
          url: '/services/mulesoft-consulting',
        },
        {
          id: 4,
          title: 'Data and Analytics Consulting',
          url: '/services/data-analytics',
        },
        {
          id: 5,
          title: 'Looker consulting',
          url: '/services/looker-consulting',
        },
        {
          id: 6,
          title: 'FinTech Solutions',
          url: '/services/fintech',
        },

        {
          id: 7,
          title: 'Spend Analysis',
          url: '/services/spend-analysis',
        },
        

        {
          id: 8,
          title: 'Salesforce Training',
          url: '/services/salesforce-training',
        },

        {
          id: 9,
          title: 'Procurement Consulting',
          url: '/services/procurement-consulting',
        },
        {
          id: 10,
          title: 'Salesforce Certification',
          url:'/services/salesforce-certification-training'
        },
        {
          id: 11,
          title: 'Products',
          url: '/products',
        }

      ]
    },
    {
      id: 4,
      title: 'Case Studies',
      url: '/about/case-studies',
      childs: [],
    },
    {
      id: 5,
      title: 'Insights',
      url: '#',
      childs: [
        {
          id: 1,
          title: 'Blog',
          url: 'https://blogs.cymetrixsoft.com/'
        },
        {
          id: 2,
          title: 'Webinar',
          url: '/webinars/marketing-automation-roi-webinar/',
        }

      ]
    },


    {
      id: 6,
      title: 'Contact',
      url: '/contact-us',
      childs: []
    },
  ])
  const [toggle, setToogle] = React.useState(false);
  console.log(navdata)
  return (
    <header className="bg-header opacity-90 fixed w-full lg:px-16 px-6 bg-white flex flex-wrap items-center lg:py-3 py-2 z-50 sma:w-screen">
      <div className="flex-1 flex justify-between items-center">
        <Link to='/'>
          <img src={logo} alt="logo"/>
        </Link>
      </div>
      <label htmlFor="menu-toggle" className="cursor-pointer md:hidden block">
        <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><title>menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
        </svg>
      </label>
      <input className="hidden" type="checkbox" id="menu-toggle" onClick={() => setToogle(!toggle)}/>
      <div className={`${toggle ? 'show': 'hidden'} md:flex md:items-center md:w-auto w-full`} id="menu">
        <nav>
          <ul className="md:flex items-center justify-between text-base text-white pt-4 md:pt-0">
            {
              navdata.map(link => {
                return (
                  <li key={link.id} className="dropdown lg:inline-flex md:p-4 py-3 px-0 block border-transparent hover:text-headerhover relative cursor-pointe uppercase">
                    <div className="flex items-center  text-lg">
                      <Link to={link.url} activeClassName="text-hover" partiallyActive={false}>{link.title}</Link>
                    </div>
                    {
                      link.childs.length === 0 ? null : 
                      <ul className="transition-all duration-75 ease-linear dropdown-menu absolute hidden text-white  pt-1 sm:mt-1 sm:z-50 md:mt-3 lg:mt-10 capitalize text-sm">
                        { 
                          link.childs.map(child => {
                            return (
                              <li key={child.id} className="">
                                <Link className="bg-blue-900 hover:text-blue-500 py-2 px-4 block whitespace-no-wrap" activeClassName="hover:text-blue-500 py-2 px-4 block whitespace-no-wrap" partiallyActive={false} to={child.url}>{child.title}</Link>
                              </li>
                            )
                          })
                        }
                      </ul>
                    }
                  </li>
                )
              })
            }
          </ul>
        </nav>
      </div>
    </header>
  );
}

LayoutHeader.propTypes = {
  siteTitle: PropTypes.string,
}

LayoutHeader.defaultProps = {
  siteTitle: ``,
}

export default LayoutHeader
