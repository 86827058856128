import React, { useState } from 'react';
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types"
import { Link } from 'gatsby';

import { TwitterTimelineEmbed } from 'react-twitter-embed';

const Twitter = () => {

    return (
        <React.Fragment>
            <div className="sma:my-3 md:space-x-5 md:flex justify-center flex-row mx-auto max-w-6xl">
                <div className="md:w-2/5">
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="cymetrix_soft"
                        options={{height: 450}}
                    />
                </div>
                <div className="sma:m-3 md:w-2/5 break-words">
                    <h1 className="text-2xl text-gray-700">
                        Stay Connected With Cymetrix
                    </h1>
                    <p className="text-lg my-3 text-gray-700">Stay connected with Cymetrix in real time. We would also love to know which are your favourite business growth technologies – send them to us with the ​#TechTalkWithCymetrix tag.</p>
                    <div className="bg-transparent text-center hover:border-blue-500 text-gray-700 text-base font-thin w-64 py-2 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm">
                        <Link target="_blank"
                            to="https://twitter.com/cymetrix_soft">Join cymetrix on twitter</Link>
                    </div>
                </div>
            </div>

            <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
                <h1 className="mt-5 text-xl font-semibold">From Our Blogs</h1>
                <div className="flex sma:flex-col sm:flex-row">
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../../images/services/business-innovation-customer-entric-salesforce.jpg')} alt="Customer-Centric Business Innovation  Blog image | Cymterix Software" title="Customer-Centric Business Innovation" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blog.cymetrixsoft.com/digital-customer-centric-business-innovation/" >Customer-Centric Business Innovation</Link></h1>
                            <p className="text-lg">How Salesforce is enabling us to move toward the sustainable customer- centric business innovation</p>
                        </div>
                    </div>
                    <div className="relative z-1 mt-3 sm:pt-3 pr-3 w-full md:w-1/2 lg:w-1/2 max-w-xl mx-auto sm:max-w-none sm:mx-0 md:flex">
                        <img src={require('./../../../images/services/cymetrix-excel-vs-crm.jpg')} alt="Why You Need To Give Up Excel For CRM Blog image | Cymetrix Software" title="Why You Need To Give Up Excel For CRM" className="h-40 w-56 sma:w-full"/>
                        <div className="flex flex-col ml-4">
                            <h1 className="text-xl text-hover"><Link to="https://blog.cymetrixsoft.com/reasons-why-you-need-to-give-up-using-excel-for-crm/">Why You Need To Give Up Excel For CRM</Link></h1>
                            <p className="text-lg">Using spreadsheets to perform CRM functions comes at a cost for the business, which is much higher than what they save</p>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    ) 
};

export default Twitter;
