import React from 'react';
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types"
import styled from "styled-components"

const BackgroundSectionC = ({img, styleClass, title, children }) => {
  return (
    <BackgroundImage className={styleClass} fluid={img}>
      <h1 className="text-base xl:text-5xl lg:text-4xl md:text-x1 sm:text-lg
      text-white text-uppercase text-center m-auto">
        {title}
      </h1>
      { children }
    </BackgroundImage>
  )
}

const BackgroundSection = styled(BackgroundSectionC)`
  width: 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`

BackgroundSection.defaultProps = {
  title: "Default Title",
  styleClass: "default-background"
};

BackgroundSection.propTypes = {
  title: PropTypes.string.isRequired,
  styleClass: PropTypes.string,
  img: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default BackgroundSection;