import React from 'react';
import { Link } from "gatsby"
import { GrFacebookOption } from 'react-icons/gr';
import { FaLinkedinIn } from 'react-icons/fa';
import { AiOutlineTwitter } from 'react-icons/ai';
import ReactTooltip from "react-tooltip";

const LayoutFooter = () => {
  const [navdata] = React.useState([
    {
      id: 1,
      title: 'Home',
      url: '/',
    },
    {
      id: 2,
      title: 'About',
      url: '/about/company',
    },
    {
      id: 3,
      title: 'Case Studies',
      url: '/about/case-studies',
    },
    {
      id: 4,
      title: 'Careers',
      url: '/career',
    },
    {
      id: 5,
      title: 'Privacy Policy',
      url: 'https://www.cymetrixsoft.com/privacypolicy',
    },
    {
      id: 6,
      title: 'Infographics',
      url: 'https://www.cymetrixsoft.com/infographics/'
    },
  ])
  const [socialButtons] = React.useState([
    {
      id: 1,
      icon: <GrFacebookOption />,
      ur1: 'https://www.facebook.com/cymetrix/',
    },
    {
      id: 2,
      icon: <AiOutlineTwitter />,
      url: 'https://twitter.com/cymetrix_soft'
    },
    {
      id: 3,
      icon: <FaLinkedinIn />,
      url: 'https://www.linkedin.com/company/cymetrixsoftwareprivatelimited?originalSubdomain=in'
    }
  ])

  return (
    <footer className="text-white body-font  ">
      <div className="bg-header p-5 flex   ">
        <div className="container mx-auto py-4 md:px-5 flex flex-wrap flex-col sm:flex-row sma:w-screen sm:pl-2">
          <div className="sma:mb-3 items-center ">
            <nav>
              <ul className="flex items-center mx-0 justify-between text-base text-white ">
                {
                  navdata.map(link => {
                    return (
                      <li key={link.id}><Link
                        className="block text-center py-3 mx-3 md:mr-5 px-0 border-b-2 border-transparent"
                        to={link.url}>{link.title}</Link></li>
                    )
                  })
                }
              </ul>
            </nav>
            <p className="text-white text-sm text-center lg:text-left lg:ml-3"><span style={{ color: '#34c3f1'}}>Cymetrix</span> © 2024 - All Right Reserved</p>
          </div>
          <div className="sm:ml-auto flex items-center sm:w-auto w-full sm:text-left text-center text-sm">
            <ul className="flex items-center justify-between text-base text-white">
              <li data-tip data-for='FaceBook'><Link
                className="flex flex-col m-auto items-center align-middle sma:py-3 sma:ml-10 ml-8 px-0 border-b-2 rounded-full border h-12 w-12 justify-center hover:border-hover hover:text-hover border-white"
                to='https://www.facebook.com/cymetrix/'><GrFacebookOption /></Link>
              </li>
              <ReactTooltip id='FaceBook' type='dark' effect='solid'>
                <span>Facebook</span>
              </ReactTooltip>
              <li data-tip data-for='Twitter'><Link
                className="flex flex-col m-auto items-center align-middle sma:py-3 sma:ml-10 ml-8 px-0 border-b-2 rounded-full border h-12 w-12 justify-center hover:border-hover hover:text-hover border-white"
                to='https://twitter.com/cymetrix_soft'><AiOutlineTwitter /></Link>
              </li>
              <ReactTooltip id='Twitter' type='dark' effect='solid'>
                <span>Twitter</span>
              </ReactTooltip>
              <li data-tip data-for='Linkedin'><Link
                className="flex flex-col m-auto items-center align-middle sma:py-3 sma:ml-10 ml-8 px-0 border-b-2 rounded-full border h-12 w-12 justify-center hover:border-hover hover:text-hovery border-white"
                to='https://www.linkedin.com/company/cymetrixsoftwareprivatelimited?originalSubdomain=in'><FaLinkedinIn /></Link>
              </li>
              <ReactTooltip id='Linkedin' type='dark' effect='solid'>
                <span>Linkedin</span>
              </ReactTooltip>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
};

export default LayoutFooter;