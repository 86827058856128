import React from 'react';

const Title = ({ title, uppercase, classValue }) => (
  <div className="section-title text-center m-8">
    <span className={`font-normal border-white border-none relative ${uppercase ? 'uppercase': ''} ${classValue} sma:text-xl sm:text-xl md:text-4xl`}>{ title }</span>
  </div>
);

export default Title;

