import React from 'react';
import { FaPhoneAlt } from 'react-icons/fa';

const CompanyPlaceInfo = ({ img, title, address, email, phone }) => {

  return (
    <div className="w-full w-1/2 px-2 sma:mt-6 sm:mt-6 md:mt-6">
      <div className="md:m-auto h-full rounded overflow-hidden shadow-md">
        <img className="w-full" src={img} alt="Sunset in the mountains" />
        <div className="flex items-center px-6 py-3 bg-gray-900">
          <h1 className="mx-3 text-white font-semibold text-lg">{ title }</h1>
        </div>
        <div className="px-6 py-4">
          <div className="flex items-center mt-4 text-gray-700">
            <i class="mdi mdi-map-marker h-6 w-6" aria-hidden="true" />
            <h1 className="px-2 text-sm text-sm">{ address }</h1>
          </div>
          <div className="flex items-center mt-4 text-gray-700">
            <i class="mdi mdi-email h-6 w-6" aria-hidden="true" />
            <h1 className="px-2 text-sm">{ email }</h1>
          </div>
          <div className="flex items-center mt-4 text-gray-700">
            <i class="mdi mdi-phone h-6 w-6" aria-hidden="true" />
            <h1 className="px-2 text-sm">{ phone }</h1>
          </div>
        </div>
      </div>

      <div>
        
      </div>
    </div>
  );
};

export default CompanyPlaceInfo;