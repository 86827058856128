import React, { useState } from 'react';
import BackgroundImage from "gatsby-background-image";
import PropTypes from "prop-types"

import "slick-carousel/slick/slick.css";

import { FaQuoteLeft, FaQuoteRight, FaArrowLeft, FaArrowRight } from 'react-icons/fa';


import Slider from "react-slick";
import tw from "twin.macro";
import styled, { css } from "styled-components/macro";
import ShowMoreText from 'react-show-more-text';
import LinesEllipsis from 'react-lines-ellipsis';

const TestimonialsSlider = styled(Slider)`
  ${tw`flex  mx-auto max-w-xs sm:max-w-xl md:max-w-4xl lg:max-w-6xl text-left bg-gray-100 rounded-lg text-gray-900`}
  .slick-track {
    ${tw`flex!`}
  }
  .slick-slide {
    ${tw`h-auto`}
  }
  .slick-slide > div {
    ${tw`h-full`}
  }
`;

const CustomerNameAndProfileContainer = tw.div`mt-4 sm:mt-0 sm:ml-4 flex flex-col`
const CustomerName = tw.span`text-lg font-semibold`
const CustomerProfile = tw.span`text-sm font-normal text-gray-700`
const ControlsContainer = tw.div`sm:ml-auto flex`
const ControlButton = styled.button`
  ${tw`text-gray-600 focus:outline-none transition-colors duration-300 ml-4 first:ml-0 sm:first:ml-4 mt-4 sm:mt-0`}
  .icon {
    ${tw`fill-current w-6`}
  }
`;

const Testonimal = () => {

    const [sliderRef, setSliderRef] = useState(null)

    const testimonials = [
        {
            customerName: "Aspira Diagnostic",
            customerProfile: "",
            imageSrc:
                "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.85&w=256&h=256&q=80",
            quote:
                "We have had an excellent experience working with Cymetrix Software on Salesforce implementation. Cymetrix Software has been great partners from setting up through to customer service. I would not hesitate to recommend them to anyone thinking of a Salesforce implementation.",
            show: false
        },
        {
            customerName: "Insolergy Technologies",
            customerProfile: "",
            imageSrc:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=256&h=256&q=80",
            quote:
            "Migrating to Salesforce for our CRM related needs turned out to be one of the best decisions we have made, and Cymetrix helped us in achieving the same as seamlessly as possible. Key challenges were to understand our business-specific needs and to migrate data from our previous CRM software, without disrupting ongoing Sales & Marketing activities. Cymetrix arranged detailed one-to-one sessions with our team, and begin onboarding early on during the process of migration. This saved us time and ensured that we land on our feet running as soon as we begin using Salesforce. We are thoroughly impressed by the professionalism and technical skills displayed by the Cymetrix support team, and we would happily recommend them to companies looking to adopt Salesforce as their first CRM software or migrating to it from their existing CRM software.",
            show: false
        },
        {
            customerName: "Winnie Manoj",
            customerProfile: "",
            imageSrc:
                "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=256&h=256&q=80",
            quote:
            "I encountered a terrible issue with my Salesforce dashboard which was incomprehensible to me in spite of having various video resources. Cymetrix consultants were patient all the time and guided me throughout. I would strongly recommend Cymetrix Software to all my connections, whoever faces any issue with Salesforce.",
            show: false
        }
    ];

    return (
        <div className="flex flex-col mx-auto my-8 max-w-6xl sma:my-3">
            <img src={require('./../../../images/case-studies/cs-border.jpg')} className="w-full h-1"/>
            <TestimonialsSlider arrows={false} ref={setSliderRef}>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="px-1 py-2 sm:px-20 sm:py-16 focus:outline-none flex! flex-col justify-between h-full">
                        <div className="relative">
                        <blockquote className="font-medium sm:font-normal relative text-sm sm:text-xl text-center sm:text-left inline-block">
                                {/* <LinesEllipsis
                                    text={testimonial.quote}
                                    maxLine='3'
                                    ellipsis='...'
                                    trimRight
                                    basedOn='letters'
                                /> */}
                               
                                <ShowMoreText
                                    lines={3}
                                    more='Show more'
                                    less='Show less'
                                    anchorClass='text-hover'
                                    expanded={false}
                                >
                                     <FaQuoteLeft className="inline"/>
                                    {testimonial.quote}
                                    <FaQuoteRight className="inline"/>
                                </ShowMoreText>
                                
                            </blockquote>
                        </div>
                        <div className="mt-8 flex items-center flex-col sm:flex-row justify-center text-center sm:text-left">
                            {/* <img className="w-16 h-16 rounded-full" src={testimonial.imageSrc} /> */}
                            <CustomerNameAndProfileContainer>
                                <CustomerName>
                                    {testimonial.customerName}
                                </CustomerName>
                                <CustomerName>
                                    {testimonial.customerProfile}
                                </CustomerName>
                            </CustomerNameAndProfileContainer>
                            <ControlsContainer>
                                <ControlButton onClick={sliderRef?.slickPrev}>
                                    <FaArrowLeft className="icon" />
                                </ControlButton>
                                <ControlButton>
                                    <FaArrowRight className="icon" onClick={sliderRef?.slickNext}/>
                                </ControlButton>
                            </ControlsContainer>
                        </div>
                        
                    </div>
                ))}
            </TestimonialsSlider>
            <img src={require('./../../../images/case-studies/cs-border.jpg')} className="w-full h-1 "/>
        </div>
    ) 
};

export default Testonimal;